import axios from "axios";

const setPreppendTxt = () => {
  if (location.hostname.includes("dev")) return "api-";
  else return "api.";
};

let url = "https://" + setPreppendTxt() + location.host;

let hostname = process.env.VUE_APP_URL_PRD;

if (hostname) url = hostname;

console.log(url);

const client = axios.create({
  baseURL: url,
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    switch (error.response?.status) {
      case 403:
        console.error("403");
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }
);

export default client;
