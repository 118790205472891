<template>
  <div id="app">
    <transition mode="out-in" appear>
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
  export default {
    name: "App",
  };
</script>

<style lang="scss">
  $theme-colors: (
    "primary": #ea1d2c,
    "secundary": #f5f5f5,
    "warning": #fff8eb,
    "yellow": #7a5200,
    "success": #1fad68,
  );
  @import "~bootstrap/scss/bootstrap.scss";
  @import "~bootstrap-vue/src/index.scss";

  @import "./assets/main.css";
</style>
