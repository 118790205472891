import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "error-redirect",
    redirect: { name: "error", params: { code: 404 } },
  },
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/HomeView"),
  },
  {
    path: "/erro/:code",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/ErrorView.vue"),
  },
  {
    path: "/regulamento",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "terms" */ "@/views/TermsView"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, _, next) => {
  // const isAuthenticated = localStorage.getItem('accessToken')

  return next();
});

export default router;
