import { accept, getUser, convertPublicId } from "@/services/user";

export default {
  namespaced: true,
  state: {
    user: null,
    accept: null,
    reqStatus: "init",
    reqSaveStatus: "init",
  },
  getters: {
    user: (state) => state.user,
    accept: (state) => state.accept,
    reqStatus: (state) => state.reqStatus,
    reqSaveStatus: (state) => state.reqSaveStatus,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_ACCEPT(state, payload) {
      state.accept = payload;
    },
    REQ_STATUS(state, payload) {
      state.reqStatus = payload;
    },
    REQ_SAVE_STATUS(state, payload) {
      state.reqSaveStatus = payload;
    },
  },
  actions: {
    async acceptReq({ commit }, params) {
      commit("REQ_SAVE_STATUS", "pending");
      try {
        const { data } = await accept(params);
        commit("REQ_SAVE_STATUS", "init");
        commit("SET_ACCEPT", data);
        return data;
      } catch (error) {
        commit("REQ_SAVE_STATUS", "init");
        throw error;
      }
    },
    async getUserReq({ commit }, id) {
      commit("REQ_STATUS", "pending");
      try {
        const { data } = await getUser(id);
        commit("REQ_STATUS", "init");
        if (data.item) commit("SET_USER", data.item);
        return data;
      } catch (error) {
        commit("REQ_STATUS", "init");
        throw error;
      }
    },
    async convertPublicIdReq({ commit }, id) {
      commit("REQ_STATUS", "pending");
      try {
        const { data } = await convertPublicId(id);
        commit("REQ_STATUS", "init");
        commit("SET_USER", data);
        return data;
      } catch (error) {
        commit("REQ_STATUS", "init");
        throw error;
      }
    },
  },
};
